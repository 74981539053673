<template>
  <v-card class="rounded-lg" color="accent">
    <v-card-title class="pb-0">
      Cadastrar cliente<v-spacer />
      <v-btn class="rounded-lg" icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <section class="pa-4">
      <v-form v-model="valid" ref="form" autocomplete="off">
        <!-- id -->
        <v-text-field
          v-model="form.id"
          label="ID do usuário"
          background-color="foreground"
          :rules="rule"
          outlined
          @keypress="isNumber($event)"
        />

        <!-- nome -->
        <v-text-field
          v-model="form.name"
          label="Nome"
          background-color="foreground"
          :rules="rule"
          outlined
        />

        <!-- card permission -->
        <v-list-item class="foreground rounded-lg mb-6">
          <v-list-item-content>
            <v-list-item-title>Depósito por cartão</v-list-item-title>

            <v-list-item-title>
              <span :class="`${status[form.status].color}--text`">
                {{ status[form.status].label }}
              </span>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="status[form.status].color"
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  dark
                  small
                >
                  {{ status[form.status].label }}

                  <v-icon right> mdi-chevron-down </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in status"
                  :key="index"
                  @click="form.status = index"
                >
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </v-form>

      <v-row no-gutters>
        <v-spacer />

        <v-btn plain @click="close()"> Cancelar </v-btn>

        <v-btn color="primary" @click="submit()"> Confirmar </v-btn>
      </v-row>
    </section>

    <loader-hover v-if="loading" />
  </v-card>
</template>

<script>
import { addCustomer } from "@/services/customers";
import { displayAlert, isNumber, capitalizeAll } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      status: [
        {
          value: true,
          label: "Permitido",
          color: "green",
        },
        {
          value: false,
          label: "Negado",
          color: "red",
        },
      ],
      form: {
        id: null,
        name: "",
        status: 0,
      },
    };
  },

  watch: {
    ["form.name"](value) {
      this.form.name = capitalizeAll(value);
    },
  },

  computed: {
    rule() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          id: this.form.id,
          name: this.form.name,
          isAllowed: this.status[this.form.status].value,
        };

        await addCustomer(payload).then(() => {
          this.displayAlert("Usuário registrado com sucesso");
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    close() {
      this.$emit("close");
    },

    isNumber,

    displayAlert,
  },
};
</script>

<style>
</style>