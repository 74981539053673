var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mx-2 mb-4 text-h6 font-weight-black align-center",attrs:{"no-gutters":""}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-account-group")]),_vm._v(" Clientes "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){_vm.dialogRegister = true}}},[_vm._v(" Criar novo ")])],1),_c('Filters',{staticClass:"mb-4",attrs:{"dataFilters":require('@/assets/filters/users.json')},on:{"apply-filter":_vm.applyFilter}}),_c('section',[_c('v-card',{staticClass:"rounded-lg",attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"accent pointer-hand",attrs:{"headers":_vm.headers,"items":_vm.users,"items-per-page":_vm.itemsPerPage,"mobile-breakpoint":0,"custom-sort":_vm.customSort,"hide-default-footer":""},on:{"dblclick:row":_vm.handleUser},scopedSlots:_vm._u([{key:"item.isAllowed",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.isAllowed ? 'green' : 'red',"small":""}},[_c('span',[_vm._v(_vm._s(item.isAllowed ? "Permitido" : "Negado"))])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.updatedAt)))])]}},{key:"item.open",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"rounded-lg",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleUser(null, { item: item })}}},[_c('v-icon',[_vm._v(" mdi-open-in-app ")])],1)]}}],null,true)})],1),_c('v-pagination',{staticClass:"py-4",attrs:{"color":"primary","length":_vm.pageCount,"total-visible":10,"circle":""},on:{"input":function($event){return _vm.getData()}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.dialogManage),callback:function ($$v) {_vm.dialogManage=$$v},expression:"dialogManage"}},[(_vm.dialogManage)?_c('ManageCustomer',{attrs:{"selected":_vm.selected},on:{"success":function($event){return _vm.getData()},"close":function($event){_vm.dialogManage = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.dialogRegister),callback:function ($$v) {_vm.dialogRegister=$$v},expression:"dialogRegister"}},[(_vm.dialogRegister)?_c('RegisterCustomer',{on:{"success":function($event){return _vm.getData()},"close":function($event){_vm.dialogRegister = false}}}):_vm._e()],1),(_vm.loading)?_c('loader-hover'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }