<template>
  <v-container class="py-4" fluid>
    <v-row class="mx-2 mb-4 text-h6 font-weight-black align-center" no-gutters>
      <v-icon class="mr-3">mdi-account-group</v-icon>
      Clientes

      <v-spacer />

      <v-btn color="primary" depressed small @click="dialogRegister = true">
        Criar novo
      </v-btn>
    </v-row>

    <Filters
      class="mb-4"
      :dataFilters="require('@/assets/filters/users.json')"
      @apply-filter="applyFilter"
    />

    <section>
      <!-- table -->
      <v-card class="rounded-lg" outlined>
        <v-data-table
          class="accent pointer-hand"
          :headers="headers"
          :items="users"          
          :items-per-page="itemsPerPage"
          :mobile-breakpoint="0"
          :custom-sort="customSort"
          hide-default-footer
          @dblclick:row="handleUser"
        >
          <template v-slot:[`item.isAllowed`]="{ item }">
            <v-chip :color="item.isAllowed ? 'green' : 'red'" small>
              <span>{{ item.isAllowed ? "Permitido" : "Negado" }}</span>
            </v-chip>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ formatDate(item.createdAt) }}</span>
          </template>

          <template v-slot:[`item.updatedAt`]="{ item }">
            <span>{{ formatDate(item.updatedAt) }}</span>
          </template>

          <!-- open -->
          <template v-slot:[`item.open`]="{ item }">
            <v-btn
              class="rounded-lg"
              icon
              small
              @click="handleUser(null, { item })"
            >
              <v-icon> mdi-open-in-app </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <!-- pagination -->
      <v-pagination
        v-model="page"
        class="py-4"
        color="primary"
        :length="pageCount"
        :total-visible="10"
        circle
        @input="getData()"
      />
    </section>

    <v-dialog v-model="dialogManage" max-width="500" persistent>
      <ManageCustomer
        v-if="dialogManage"
        :selected="selected"
        @success="getData()"
        @close="dialogManage = false"
      />
    </v-dialog>

    <v-dialog v-model="dialogRegister" max-width="500" persistent>
      <RegisterCustomer
        v-if="dialogRegister"
        @success="getData()"
        @close="dialogRegister = false"
      />
    </v-dialog>

    <loader-hover v-if="loading" />
  </v-container>
</template>

<script>
import { getCustomers } from "@/services/customers";
import { displayAlert, formatDate } from "@/utils";
import Filters from "@/components/filters/Filters";
import ManageCustomer from "@/components/customer/ManageCustomer";
import RegisterCustomer from "@/components/customer/RegisterCustomer";

export default {
  data() {
    return {
      interval: null,
      loading: true,
      dialogManage: false,
      dialogRegister: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      sort: "createdAt,desc",
      headers: [
        { text: "Perm. de cartão", value: "isAllowed",  align: "center" },
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Data de registro", value: "createdAt" },
        { text: "Data de atualização", value: "updatedAt" },
        { text: "Abrir", value: "open", align: "center", sortable: false },
      ],
      users: [],
      filter: [],
      selected: null,
    };
  },

  components: {
    Filters,
    ManageCustomer,
    RegisterCustomer,
  },

  beforeMount() {
    this.getData();

    this.interval = setInterval(() => {
      if (!this.loading) this.getData();
    }, 5000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    async getData() {
      try {
        const payload = {
          page: String(this.page - 1),
          size: this.itemsPerPage,
          sort: this.sort,
          ...this.filter,
        };

        await getCustomers(payload).then((res) => {
          this.users = res.body.content;
          this.pageCount = res.body.totalPages;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleUser(event, { item }) {
      this.selected = item;
      this.dialogManage = true;
    },

    // get data with filter
    applyFilter(value) {
      this.loading = true;
      this.filter = value;
      this.page = 1;

      this.getData();
    },

    // sort table
    customSort(items, sortBy, sortDesc) {
      let value = sortBy[0];
      let sort = "";

      if (value) sort = value + "," + (sortDesc[0] ? "desc" : "asc");
      else sort = "createdAt,desc";

      if (sort !== this.sort) {
        this.sort = sort;
        this.getData();
      }

      return items;
    },

    displayAlert,

    formatDate,
  },
};
</script>