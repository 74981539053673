import { request } from "./index";

export const getCustomers = (data) =>
  request(
    "get",
    `/client?page=${data.page}&size=${data.size}&sort=${data.sort}&id=${data.id}&name=${data.name}&isAllowed=${data.isAllowed}`
  );

export const addCustomer = (data) => request("post", "/client", data);

export const customerCardAllowed = (data) =>
  request("post", "/client/update", data);
