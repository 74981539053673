<template>
  <v-card class="rounded-lg" color="accent">
    <v-card-title class="pb-0">
      Gerenciamento de cliente <v-spacer />
      <v-btn class="rounded-lg" icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <section class="pa-4">
      <!-- user info -->
      <v-list class="foreground pa-0 rounded-lg mb-2">
        <v-list-item>
          <v-list-item-action>
            <v-list-item-subtitle> Nome </v-list-item-subtitle>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title class="text-right">
              <input
                v-model="selected.name"
                id="name"
                class="text-right primary_text--text field"
                readonly
                @focus="copy('name')"
              />
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon class="px-0">
            <v-icon color="secondary" small @click="copy('name')">
              mdi-content-copy
            </v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item>
          <v-list-item-action>
            <v-list-item-subtitle> ID do usuário </v-list-item-subtitle>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title class="text-right">
              <input
                v-model="selected.id"
                id="id"
                class="text-right primary_text--text field"
                readonly
                @focus="copy('id')"
              />
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon class="px-0">
            <v-icon color="secondary" small @click="copy('id')">
              mdi-content-copy
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>

      <!--status -->
      <v-list-item class="foreground rounded-lg mb-6">
        <v-list-item-content>
          <v-list-item-title>Depósito por cartão</v-list-item-title>

          <v-list-item-title>
            <span :class="`${status[form.status].color}--text`">
              {{ status[form.status].label }}
            </span>
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="status[form.status].color"
                v-bind="attrs"
                v-on="on"
                depressed
                small
              >
                {{ status[form.status].label }}

                <v-icon right> mdi-chevron-down </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in status"
                :key="index"
                @click="form.status = index"
              >
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>

      <v-row no-gutters>
        <v-spacer />

        <v-btn plain @click="close()"> Cancelar </v-btn>

        <v-btn color="primary" @click="submit()"> Confirmar </v-btn>
      </v-row>
    </section>

    <loader-hover v-if="loading" />
  </v-card>
</template>

<script>
import { customerCardAllowed } from "@/services/customers";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      status: [
        {
          value: true,
          label: "Permitido",
          color: "green",
        },
        {
          value: false,
          label: "Negado",
          color: "red",
        },
      ],
      form: {
        status: 0,
        motive: "",
      },
    };
  },

  props: {
    selected: {
      type: Object,
      default: {},
    },
  },

  beforeMount() {
    this.form.status = this.selected.isAllowed ? 0 : 1;
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        const payload = {
          id: this.selected.id,
          isAllowed: this.status[this.form.status].value,
        };

        await customerCardAllowed(payload).then(() => {
          this.displayAlert("Usuário atualizado com sucesso");
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    // copy do clipboard
    copy(value) {
      var textBox = document.getElementById(value);
      textBox.select();
      document.execCommand("copy");
    },

    close() {
      this.$emit("close");
    },

    displayAlert,
  },
};
</script>

<style lang="scss" scoped>
.field:focus {
  outline: none !important;
  border: 0px;
}
</style>